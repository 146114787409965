import moment from 'moment'

export const formatDate = (date) => {
  return moment(date).format('MM/DD/YYYY')
}

export const formatYear = (year) => {
  return moment(year).format('YYYY')
}

export const UTCFormatDate = (date) => {
  return moment.utc(date).format('MM/DD/YYYY')
}

export const formatDateInPlan = (date) => {
  return moment(date).format('YYYY-MM-DD')
}

export const formatDateInDetail = (date) => {
  return moment(date).format('MM-DD-YYYY')
}

export const UTCFormatItineary = (date) => {
  return moment.utc(date).format('MM-DD-YYYY')
}

export const convertSessionDate = (date, format = 'MM/DD/YYYY') => {
  return date ? moment(date).utcOffset('+0000').format(format) : null
}

export const currentYearFormat = () => {
  return moment().year()
}

export const getCurrentDate = () => moment()

export const getcurrentDateUsingProps = (date) => {
  return moment(date)
}

export const getItineraryDate = (date) => moment(date).format('MMMM D, YYYY')

export const getIntentionDate = (date) => moment(date).format('MMMM Do YYYY')

export const getArchivedIntentionDate = (date) =>
  moment(date).format('Do MMMM YYYY')

export const sortedByDate = (data) => {
  return data?.sort((a, b) => {
    const startDateA = moment(a.startDate)
    const startDateB = moment(b.startDate)

    if (startDateA > startDateB) {
      return 1
    } else if (startDateA < startDateB) {
      return -1
    } else {
      // startDateA === startDateB
      const endDateA = moment(a.endDate)
      const endDateB = moment(b.endDate)
      if (endDateA > endDateB) {
        return 1
      } else if (endDateA < endDateB) {
        return -1
      } else {
        return 0 // endDateA === endDateB
      }
    }
  })
}
