import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Button, FeatherLoader, useErrorService } from '@abroad/components'
import API from '../../utils/API'
import { UTCFormatDate } from '../../utils/date'

const CreatedQuestsTable = () => {
  const history = useHistory()
  const Error = useErrorService()
  const [questList, setQuestList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const getLocation = async () => {
      try {
        setIsLoading(true)
        const { data } = await API.AdminAPI.quests.getQuestList()
        setQuestList(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    getLocation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getStatus = (startDate, endDate) => {
    const currentDate = UTCFormatDate()
    const start = UTCFormatDate(startDate)
    const end = UTCFormatDate(endDate)

    if (currentDate < start || startDate === null || endDate === null) {
      return 'Upcoming'
    } else if (currentDate >= start && currentDate <= end) {
      return 'In Progress'
    } else {
      return 'Completed'
    }
  }

  return (
    <>
      <Helmet>
        <title>{`Created-Quest | Abroad`}</title>
        <meta name='title' content={'Created-Quest | Abroad'}></meta>
        <meta property='og:title' content={'Created-Quest | Abroad'}></meta>
        <meta
          property='twitter:title'
          content={'Created-Quest | Abroad'}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/admin/quests`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/quests`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/admin/quests`}></meta>
      </Helmet>
      <div>
        <div className='d-flex justify-content-between'>
          <h3 className='mt-5 s3'>Quests</h3>
          <div>
            <Button
              variant='gray'
              className='px-4 py-3 abroad-blue mt-3'
              type='button'
              onClick={() => history.push(`/admin/quests/create-new`)}>
              ADD NEW QUEST
            </Button>
          </div>
        </div>

        <Table responsive className='custom-table mt-3'>
          <thead>
            <tr>
              <th>NAME</th>
              <th className='text-center'>START DATE</th>
              <th className='text-center'>END DATE</th>
              <th className='text-center'>STATUS</th>
              <th className='text-center'>ACTIONS</th>
            </tr>
          </thead>
          {isLoading && (
            <tr>
              <td className='text-center border-0 pt-3' colSpan='5'>
                <FeatherLoader />
              </td>
            </tr>
          )}
          {questList.length === 0 && !isLoading && (
            <tr>
              <td className='text-center s6 py-3' colSpan='5'>
                No quests for this location. Please use Add new quest button to
                add quest.
              </td>
            </tr>
          )}
          <tbody>
            {questList.length > 0 &&
              !isLoading &&
              questList.map((quest) => (
                <tr key={quest.id}>
                  <td>{quest.name ? quest.name : '-'}</td>
                  <td className='text-center'>
                    {quest.startDate === null
                      ? '-'
                      : UTCFormatDate(quest.startDate)}
                  </td>
                  <td className='text-center'>
                    {quest.endDate === null
                      ? '-'
                      : UTCFormatDate(quest.endDate)}
                  </td>
                  <td className='text-center'>
                    {getStatus(quest.startDate, quest.endDate)}
                  </td>
                  <td
                    className='text-saffron-700 cursor-pointer text-center'
                    onClick={() => history.push(`/admin/quests/${quest?._id}`)}>
                    Manage
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default CreatedQuestsTable
